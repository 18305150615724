.translate-x-full-plus-one {
  --tw-translate-x: 101%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.gradient-animate {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.checkbox:checked + label .radio {
  background-color: rgb(6 182 212);
  box-shadow: 0px 0px 0px 3px white inset;
}

.dark .checkbox:checked + label .radio {
  box-shadow: 0px 0px 0px 3px rgb(17 24 39) inset;
}
