@font-face {
  font-family: "Raleway";
  src: local("RalewayRegular"),
    url("./fonts/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RalewayLight";
  src: local("RalewayLight"),
    url("./fonts/Raleway-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RalewayMedium";
  src: local("RalewayMedium"),
    url("./fonts/Raleway-Medium.ttf") format("truetype");
}
@tailwind base;
@tailwind components;
@tailwind utilities;
